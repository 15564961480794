import React, { useState, useEffect, useCallback } from 'react';
import { variable } from '../../../Variable';

const UserWaterTreatment = ({ show, onClose, user }) => {
  const [waterTreatments, setWaterTreatments] = useState([]);
  const [userWaterTreatments, setUserWaterTreatments] = useState([]);
  const [filteredWaterTreatments, setFilteredWaterTreatments] = useState([]);
  const [filteredUserWaterTreatments, setFilteredUserWaterTreatments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchAll, setSearchAll] = useState('');
  const [searchUser, setSearchUser] = useState('');

  const fetchWaterTreatments = useCallback(async () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    try {
      const response = await fetch(variable.API_URL + '/WaterTreatment', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setWaterTreatments(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching water treatments:', error);
      setLoading(false);
    }
  }, []); // This function does not depend on anything
  
  const fetchUserWaterTreatments = useCallback(async () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    try {
      const response = await fetch(`${variable.API_URL}/UserWaterTreatments/${user.user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token, // Добавляем токен в заголовок
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user water treatments');
      }
      const data = await response.json();
      setUserWaterTreatments(data.map((item) => item.waterTreatment));
    } catch (error) {
      console.error('Error fetching user water treatments:', error);
    }
  }, [user.user_id]);
  
  useEffect(() => {
    if (show) {
      fetchWaterTreatments();
      fetchUserWaterTreatments();
    }
  }, [show, fetchWaterTreatments, fetchUserWaterTreatments]);
  

  const filterWaterTreatments = useCallback(() => {
    setFilteredWaterTreatments(
      waterTreatments.filter((wt) =>
        wt.watertreatment_name.toLowerCase().includes(searchAll.toLowerCase())
      )
    );
  }, [searchAll, waterTreatments]);

  const filterUserWaterTreatments = useCallback(() => {
    setFilteredUserWaterTreatments(
      userWaterTreatments.filter((wt) =>
        wt.watertreatment_name.toLowerCase().includes(searchUser.toLowerCase())
      )
    );
  }, [searchUser, userWaterTreatments]);

  useEffect(() => {
    filterWaterTreatments();
  }, [searchAll, waterTreatments, filterWaterTreatments]);

  useEffect(() => {
    filterUserWaterTreatments();
  }, [searchUser, userWaterTreatments, filterUserWaterTreatments]);

  const handleAddWaterTreatment = async (waterTreatment) => {
    try {
      const response = await fetch(`${variable.API_URL}/UserWaterTreatments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify({
          user_id: user.user_id,
          watertreatment_id: waterTreatment.watertreatment_id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add water treatment');
      }

      setUserWaterTreatments((prevUserWaterTreatments) => [
        ...prevUserWaterTreatments,
        waterTreatment,
      ]);
    } catch (error) {
      console.error('Error adding water treatment:', error);
    }
  };

  const handleRemoveWaterTreatment = async (watertreatment_id) => {
    try {
      const response = await fetch(`${variable.API_URL}/UserWaterTreatments?user_id=${user.user_id}&watertreatment_id=${watertreatment_id}`, {
        method: 'DELETE',
        headers: {'Authorization': 'Bearer ' +  localStorage.getItem('token')}
      });

      if (!response.ok) {
        throw new Error('Failed to remove water treatment');
      }

      setUserWaterTreatments((prevUserWaterTreatments) =>
        prevUserWaterTreatments.filter((wt) => wt.watertreatment_id !== watertreatment_id)
      );
    } catch (error) {
      console.error('Error removing water treatment:', error);
    }
  };

  const isWaterTreatmentAdded = (id) => {
    return userWaterTreatments.some((wt) => wt.watertreatment_id === id);
  };

  if (!show) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Водоподготовки {user.user_name}</h5>
          </div>
          <div className="modal-body">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <h6>Все Водоподготовки</h6>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Поиск по имени..."
                    value={searchAll}
                    onChange={(e) => setSearchAll(e.target.value)}
                  />
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {filteredWaterTreatments.map((wt) => (
                      <div key={wt.watertreatment_id} className="d-flex flex-column mb-3 p-2 border rounded">
                        <div className="mb-1"><strong>{wt.watertreatment_name}</strong></div>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleAddWaterTreatment(wt)}
                          disabled={isWaterTreatmentAdded(wt.watertreatment_id)}
                        >
                          {isWaterTreatmentAdded(wt.watertreatment_id) ? 'Добавлено' : 'Добавить'}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Добавленные</h6>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Поиск по имени..."
                    value={searchUser}
                    onChange={(e) => setSearchUser(e.target.value)}
                  />
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {filteredUserWaterTreatments.map((wt) => (
                      <div key={wt.watertreatment_id} className="d-flex flex-column mb-3 p-2 border rounded">
                        <div className="mb-1"><strong>{wt.watertreatment_name}</strong></div>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleRemoveWaterTreatment(wt.watertreatment_id)}
                        >
                          Снять
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWaterTreatment;
