import React, { useState } from 'react';
import AddWaterTreatmentModal from './Modal/AddAddWaterTreatmentModal';
const AddWaterTreatmentBtn = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div className="text-end">
        <button type="button" className="btn text-bg-dark mt-1" onClick={() => setModalShow(true)}>
          Добавить новую водоподготовку
        </button>
      </div>
      <AddWaterTreatmentModal
        show={modalShow}
        onClose={() => setModalShow(false)}
      />
    </>
  );
};

export default AddWaterTreatmentBtn;
