import React, { useState } from 'react';
import AddUserModal from './AddUser'; // Убедитесь, что путь правильный

const AddUserButtonWithModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <button type="button" className="btn text-bg-dark" onClick={handleShowModal}>
        Добавить нового пользователя
      </button>

      {/* Передаем проп show в AddUserModal */}
      <AddUserModal show={showModal} onClose={handleCloseModal} />
    </div>
  );
};

export default AddUserButtonWithModal;
