import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { variable } from '../../../Variable';

const EditWaterTreatmentModal = ({ show, onHide, onSave, treatment, types, API_URL }) => {
  const [name, setName] = useState('');

  // When the modal opens, set the current state based on the passed data
  useEffect(() => {
    if (treatment) {
      setName(treatment.watertreatment_name);
    }
  }, [treatment]);

  const handleSave = async () => {
    // Подтверждение перед сохранением
    if (!window.confirm(`Вы уверены, что хотите сохранить изменения для WaterTreatment ${treatment.watertreatment_id}?`)) {
      return;
    }
  
    // Обновленный объект лечения
    const updatedTreatment = {
      watertreatment_name: name,
    };
  
    try {
      // Отправка PUT-запроса
      const response = await fetch(`${variable.API_URL}/WaterTreatment/${treatment.watertreatment_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token') 
        },
        body: JSON.stringify(updatedTreatment),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert(result); // Сообщение об успешном обновлении
        onSave({ ...treatment, watertreatment_name: name }); // Обновление состояния родительского компонента
        onHide(); // Закрытие модального окна
      } else {
        alert(result); // Сообщение об ошибке
      }
    } catch (error) {
      console.error('Ошибка при обновлении WaterTreatment:', error);
      alert('Ошибка при обновлении WaterTreatment');
    }
  };;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Редактировать водоподготовку</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formWaterTreatmentName">
            <Form.Label>Имя водоподготовки</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите имя водоподготовки"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Отмена
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Сохранить изменения
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditWaterTreatmentModal;
