import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AddPhoto = ({ show, handleClose, handleSave }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSaveClick = () => {
    if (selectedFile) {
      handleSave(selectedFile);
      handleClose(); // Закрываем модальное окно после сохранения
    } else {
      alert('Пожалуйста, выберите файл.');
    }
  };

  // Обработчик закрытия модального окна
  const handleModalClose = () => {
    setSelectedFile(null); // Сбрасываем выбранный файл
    handleClose(); // Вызываем родительский обработчик закрытия
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Добавить фото</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="border border-dashed rounded p-5 text-center"
          style={{ minHeight: '200px' }}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          {selectedFile ? (
            <p>{selectedFile.name}</p>
          ) : (
            <div>
              <p>Перетащите изображение сюда или нажмите кнопку ниже для выбора файла</p>
            </div>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="form-control mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Закрыть
        </Button>
        <Button variant="dark" onClick={handleSaveClick}>
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPhoto;
