import React from 'react';

const Alert = ({ message, type, onClose }) => {
  return (
    <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
      <div className="d-flex justify-content-between align-items-center">
      <div className="mx-auto">
          <strong>{message}</strong>
        </div>
        <button type="button" className="btn" onClick={onClose} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
}

export default Alert;
