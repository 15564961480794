import React, { Component } from 'react';
import AdminHeader from '../Header';
import WaterTreatmentTable from './WaterTreatmentTable';
import AddWaterTreatmentBtn from './AddWaterTreatmentBtn';

export class WaterTreatments extends Component {
    componentDidMount() {
        // Проверяем, если пользователь авторизован и является администратором
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || user.user_type !== 'admin') {
            window.location.href = '/LogInAdmin';
        }
    }

    render() {
        return (
            <div>
                <AdminHeader />
                <div className="m-3 d-flex justify-content-center">
                    <AddWaterTreatmentBtn />
                </div>
                <div className="">
                    <WaterTreatmentTable />
                </div>
            </div>
        );
    }
}

export default WaterTreatments;
