import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { variable } from '../../../Variable';

const AddUserModal = ({ show, onClose }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userType, setUserType] = useState('user'); // Новый стейт для типа пользователя
  const [error, setError] = useState('');

  const resetForm = () => {
    setUserName('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setUserType('user'); // Сброс типа пользователя к значению по умолчанию
    setError('');
  };

  useEffect(() => {
    if (show) {
      resetForm();
    }
  }, [show]);

  const handleAddUser = () => {
    if (!userName || !password || !confirmPassword || !email || !firstName || !lastName) {
      setError('Все поля должны быть заполнены.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Пароль и подтверждение пароля не совпадают.');
      return;
    }

    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

    const data = {
      user_name: userName,
      password_hash: hashedPassword,
      mail: email,
      first_name: firstName,
      last_name: lastName,
      user_type: userType, // Добавляем тип пользователя в запрос
    };

    fetch(variable.API_URL + '/User', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === 'Added Successfully') {
          alert('Пользователь успешно добавлен!');
          window.location.reload();
        } else {
          setError('Ошибка при добавлении пользователя.');
        }
      })
      .catch((error) => {
        setError('Ошибка при отправке запроса.');
        console.error('Error:', error);
      });
  };

  if (!show) return null;

  return (
    <div className="modal show d-block" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Добавить нового пользователя</h5>
          </div>
          <div className="modal-body">
            {error && (
              <div
                className="alert alert-danger alert-dismissible fade show position-relative"
                role="alert"
                style={{ paddingRight: '2.5rem' }}
              >
                {error}
                <button
                  type="button"
                  className="close position-absolute"
                  onClick={() => setError('')}
                  aria-label="Close"
                  style={{
                    top: '0.5rem',
                    right: '0.75rem',
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '1.25rem',
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <div className="form-group">
              <label>Имя</label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Фамилия</label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Логин</label>
              <input
                type="text"
                className="form-control"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Пароль</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Подтвердите пароль</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Электронная почта</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Тип пользователя</label>
              <select
                className="form-control"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                required
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Закрыть
            </button>
            <button type="button" className="btn btn-dark" onClick={handleAddUser}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
