import React, { useEffect, useState } from 'react';
import UserHeader from './Components/UserHeader';
import { checkUserAuthorization } from '../Security/AuthUser';
import { variable } from '../Variable';
import { useNavigate } from 'react-router-dom'; 

const Selecting = () => {
    const [waterTreatments, setWaterTreatments] = useState([]);
    const [types, setTypes] = useState([]);
    const navigate = useNavigate(); // Инициализируем навигацию

    useEffect(() => {
        checkUserAuthorization();
        fetchUserWaterTreatments(); // Получаем данные при загрузке компонента
        fetchWaterTreatmentTypes(); // Получаем типы водоподготовки
    }, []);

    const fetchUserWaterTreatments = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            
    
            const response = await fetch(variable.API_URL + '/UserWaterTreatments/' + user.user_id, {
                method: 'GET', // Указываем метод
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token') 
                }
            });
    
            if (!response.ok) {
                if (response.status === 401) {
                    // Перенаправляем пользователя на страницу 401
                    window.location.href = '/login'; 
                }
                throw new Error(`Ошибка ${response.status}: ${response.statusText}`); // Обрабатываем другие ошибки
            }
    
            const data = await response.json();
            setWaterTreatments(data); // Обновляем состояние с водоподготовками
        } catch (error) {
            console.error('Ошибка при получении данных водоподготовок:', error);
        }
    };
    
    const fetchWaterTreatmentTypes = async () => {
        try {
            const response = await fetch(variable.API_URL + '/WaterTreatmentType');
            const data = await response.json();
            setTypes(data); // Обновляем состояние с типами водоподготовки
        } catch (error) {
            console.error('Ошибка при получении данных типов водоподготовок:', error);
        }
    };

    // Функция для получения названия типа по его ID
    const getTypeNameById = (typeId) => {
        const type = types.find((t) => t.typeId === typeId);
        return type ? type.name : 'Неизвестный тип';
    };

    const handleWaterTreatmentClick = (treatment) => {
        // Переход на страницу WaterTreatmentIndications с передачей состояния
        navigate('/WaterTreatmentIndications', { state: { treatment } });
    };

    return (
        <div>
            <UserHeader />
            <div className="m-3 d-flex justify-content-center">
                <h3>Водоподготовки</h3>
            </div>

            <div className="d-flex flex-column align-items-center">
                <table className="table table-striped mx-auto">
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Тип</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {waterTreatments.map((item, index) => (
                            <tr key={index}>
                                <td>{item.waterTreatment.watertreatment_name}</td>
                                <td>{getTypeNameById(item.waterTreatment.type_id)}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleWaterTreatmentClick(item.waterTreatment)}
                                    >
                                        Параметры
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Selecting;
