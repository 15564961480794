import LogIn from './Security/LogIn';
import { WaterTreatments } from './Admin/WaterTreatments/WaterTreatments';
import { Users } from './Admin/User/Users';
import WaterTreatmentIndications from './Components/WatertreatmentIndications/WaterTreatmentIndications';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ProtectedRoute from './Admin/ProtectedRoute';
import Selecting from './User/Selecting';
import './App.css';

function App() {
  return (
    <div className="container" >
    <BrowserRouter>

    <Routes>
      <Route path="/" element={<LogIn />}/>
      <Route path="/LogIn" element={<LogIn />} />
      <Route path="/WaterTreatmentIndications" element={<WaterTreatmentIndications />} />
      <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
          <Route path="/admin/users" element={<Users />} />
          <Route path="/Admin/WaterTreatments" element={<WaterTreatments />} />
       </Route>

       <Route path="/LogIn" element={<LogIn />}/>
       <Route path="/Selecting" element={<Selecting />}/>
     </Routes>
     
    </BrowserRouter>
    </div>
  );
}

export default App;
